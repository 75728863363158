import DataHandler from "../handler/DataHandler";

const PWA_BASE_URL = {
    STOW: {
        beta: {
            NA: "https://na.sew-integ.dolphinprod.last-mile.amazon.dev/",
            EU: "https://na.sew-integ.dolphinprod.last-mile.amazon.dev/",
            FE: "https://na.sew-integ.dolphinprod.last-mile.amazon.dev/",
            IN: "https://na.sew-integ.dolphinprod.last-mile.amazon.dev/"
        },
        gamma: {
            NA: "https://na.sew-master.dolphinprod.last-mile.amazon.dev/",
            EU: "https://eu.sew-master.dolphinprod.last-mile.amazon.dev/",
            FE: "https://fe.sew-master.dolphinprod.last-mile.amazon.dev/",
            IN: "https://in.sew-master.dolphinprod.last-mile.amazon.dev/"
        },
        prod: {
            NA: "https://na.sew.dolphinprod.last-mile.amazon.dev/",
            EU: "https://eu.sew.dolphinprod.last-mile.amazon.dev/",
            FE: "https://fe.sew.dolphinprod.last-mile.amazon.dev/",
            IN: "https://in.sew.dolphinprod.last-mile.amazon.dev/"
        }
    },
    PICK: {
        beta: {
            NA: "https://fe-integ-pick.dolphinprod.last-mile.amazon.dev/",
            EU: "https://fe-integ-pick.dolphinprod.last-mile.amazon.dev/",
            FE: "https://fe-integ-pick.dolphinprod.last-mile.amazon.dev/",
            IN: "https://fe-integ-pick.dolphinprod.last-mile.amazon.dev/"
        },
        gamma: {
            NA: "https://na-master-pick.dolphinprod.last-mile.amazon.dev/",
            EU: "https://eu-master-pick.dolphinprod.last-mile.amazon.dev/",
            FE: "https://fe-master-pick.dolphinprod.last-mile.amazon.dev/",
            IN: "https://eu-master-pick.dolphinprod.last-mile.amazon.dev/"
        },
        prod: {
            NA: "https://na-prod-pick.dolphinprod.last-mile.amazon.dev/",
            EU: "https://eu-prod-pick.dolphinprod.last-mile.amazon.dev/",
            FE: "https://fe-prod-pick.dolphinprod.last-mile.amazon.dev/",
            IN: "https://in-prod-pick.dolphinprod.last-mile.amazon.dev/"
        }
    },
    STAGE: {
        beta: {
            NA: "https://fe-integ-stage.dolphinprod.last-mile.amazon.dev/",
            EU: "https://fe-integ-stage.dolphinprod.last-mile.amazon.dev/",
            FE: "https://fe-integ-stage.dolphinprod.last-mile.amazon.dev/",
            IN: "https://fe-integ-stage.dolphinprod.last-mile.amazon.dev/"
        },
        gamma: {
            NA: "https://na-master-stage.dolphinprod.last-mile.amazon.dev/",
            EU: "https://eu-master-stage.dolphinprod.last-mile.amazon.dev/",
            FE: "https://fe-master-stage.dolphinprod.last-mile.amazon.dev/",
            IN: "https://eu-master-stage.dolphinprod.last-mile.amazon.dev/"
        },
        prod: {
            NA: "https://na-prod-stage.dolphinprod.last-mile.amazon.dev/",
            EU: "https://eu-prod-stage.dolphinprod.last-mile.amazon.dev/",
            FE: "https://fe-prod-stage.dolphinprod.last-mile.amazon.dev/",
            IN: "https://in-prod-stage.dolphinprod.last-mile.amazon.dev/"
        }
    }
};

const moduleToDolphinModuleMap = {
    STOW: "DirectedStow",
    PICK: "DirectedPick",
    STAGE: "DirectedStage"
};

export default {
    /**
     * Returns URL of given Dolphin module name
     * @param moduleName dolphin module name
     * @returns {*}
     */
    getBaseURLForModule(moduleName) {
        moduleName = moduleName.toUpperCase();
        if (PWA_BASE_URL[moduleName]) {
            return PWA_BASE_URL[moduleName];
        } else {
            throw new Error("Base url for " + moduleName + " doesnt exist");
        }
    },

    getVersionedBaseURLForModule(moduleName) {
        moduleName = moduleName.toUpperCase();
        if (PWA_BASE_URL[moduleName]) {
            const version = this.getVersionForModule(moduleToDolphinModuleMap[moduleName]);
            return {url: PWA_BASE_URL[moduleName], version: version};
        } else {
            throw new Error("Base url for " + moduleName + " doesnt exist");
        }
    },
    /**
     * Returns Query params required to initialize dolphin PWA
     * @returns {URLSearchParams}
     */
    getInitializationQueryParams() {
        return DataHandler.params;
    },

    getVersionForModule(moduleName) {
        return (DataHandler.moduleVersionMap) ? DataHandler.moduleVersionMap[moduleName] : "";
    }
}