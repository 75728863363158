import closeTokens from "@amzn/meridian-tokens/base/icon/close";
import Text from "@amzn/meridian/text";
import React from "react";
import Box from "@amzn/meridian/box";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import PropTypes from "prop-types";
import Button from "@amzn/meridian/button";
import Icon from "@amzn/meridian/icon";
import './helpview.css';
import Divider from "@amzn/meridian/divider";

export default function HelpView(props) {

    return <Column className={"helpView"} width={"100%"} height={"100%"} backgroundColor="#ffffff">
        <Row backgroundColor="#252829" width="100%" widths={["fill"]} spacingInset="medium" spacing="none">
            <Text type="h200" color={"inverted"} alignment={"center"}>{props.title}</Text>
            <div style={{position: "absolute", right: 0}}>
                <Row spacingInset={"small"} spacing={"none"}>
                    <Button label={"close"} type="icon" color={"inverted"} onClick={props.onClose}>
                        <Text color={"inverted"}><Icon tokens={closeTokens}/></Text>
                    </Button>
                </Row>
            </div>
        </Row>
        {props.list && props.list.length ?
            <Column width={"100%"} spacingInset={"medium"}>
                <Text type="h200">{props.pageTitle}</Text>
                <Box spacing={"none"} type={"outline"}>
                    {props.list.map((option, index) => {
                        return <div key={index} onClick={option.onClick} style={{cursor: "pointer"}}>
                            <Column spacingInset="medium" spacing={"none"}>
                                <Text type="b200">{option.title}</Text>
                            </Column>
                            {
                                props.list.length !== index + 1 &&
                                <Divider size={"small"} spacingAfter={"none"} spacingBefore={"none"}/>
                            }
                        </div>
                    })}
                </Box>
            </Column> :
            <Column alignmentHorizontal="center" spacingInset={"medium"} spacing={"medium"}>
                <Text type="b200" alignment="center">Please <b>see your station manager</b> for any immediate
                    help</Text>
                <img src={props.help_icon} width="50%" alt={"no help options available"}/>
                <Text type="b200" alignment="center">Help Center coming soon...</Text>
            </Column>
        }
    </Column>;
}

HelpView.propTypes = {
    title: PropTypes.string,
    pageTitle: PropTypes.string,
    onClose: PropTypes.func,
    help_icon: PropTypes.string.isRequired,
    list: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        onClick: PropTypes.func
    }))
}