import Analytics from "@aws-amplify/analytics";
import Auth from "@aws-amplify/auth";
import {
    dolphinEventsBuilder, dolphinMetricsBuilder
} from "../model/DolphinAnalyticEvents";
import AnalyticEventKeys from "./AnalyticEventKeys";
import {getDolphinAttributes} from "./ModuleAnalyticsUtility";

export default {
    region: "",
    async initializeAnalytics(region){
        this.region = region;
        const authConfig = {
            identityPoolId: 'us-east-1:6b7aa5e1-583d-4a91-8d21-c2d3a337ade0',
            region: 'us-east-1'
        };

        const pinpointConfig = {
            autoSessionRecord: false,
            AWSPinpoint: {
                // Amazon Pinpoint App Client ID
                appId: '5e0cafc38eb2450da61e6ffadf092462',
                // Amazon service region
                region: 'us-east-1',
                mandatorySignIn: false,
                bufferSize: 100,
                resendLimit: 5
            }
        };
        Auth.configure(authConfig);
        Analytics.configure(pinpointConfig);

    },
    executeAPIAnalytics(module, startTime, failure) {

        const dolphinAttributes = getDolphinAttributes(this.region);
        dolphinAttributes.setModule(module)
            .setDescription(AnalyticEventKeys.Modules.PWA)
            .build();

        const dolphinMetrics = new dolphinMetricsBuilder()
            .setDuration(Date.now() - startTime)
        if (failure === true) {
            dolphinMetrics.setFailures()
                .build();
        }

        const dolphinAnalytics = new dolphinEventsBuilder()
            .setName(module)
            .setAttributes(dolphinAttributes)
            .setMetrics(dolphinMetrics)
            .build();
        Analytics.record(dolphinAnalytics);
    },

    processAnalytics(startTime, description, response, module, scanContent, description1, eventName, failure, routeCode) {

        const dolphinAttributes = getDolphinAttributes(this.region);
        dolphinAttributes.setModule(module)
            .setDescription(description)
            .setScanContent(scanContent)
            .setDescription1(description1)

        if (routeCode) {
            dolphinAttributes.setRouteCode(routeCode)
        }

        dolphinAttributes.build();

        const dolphinMetrics = new dolphinMetricsBuilder()
            .setDuration(Date.now() - startTime)

        if (failure === true) {
            dolphinMetrics.setFailures()
                .build();
        }

        const dolphinAnalytics = new dolphinEventsBuilder()
            .setName(eventName)
            .setAttributes(dolphinAttributes)
            .setMetrics(dolphinMetrics)
            .build();

        Analytics.record(dolphinAnalytics);
    }
}
