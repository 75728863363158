import React, {Component} from 'react';
import Box from "@amzn/meridian/box";
import PropTypes from "prop-types";
import './appscanner.css';
import {Logger} from "@amzn/dolphin-web-framework";
import {getContainerId} from "../Utils/ScannerUtils";

class AppScanner extends Component {

    componentDidUpdate() {
        this.requestFocus(200);
    }

    keyDown = (e) => {
        if (e.key === "Enter" || e.keyCode === 13) {
            if (!this.scanRef.value) {
                Logger.log.info("Empty Scan received in AppScanner 2.0");
                return;
            }
            const data = getContainerId(this.scanRef.value);
            this.props.onScan(data);
            this.scanRef.value = "";
        }
    };

    requestFocus = (time) => {
        if (this.props.scannerEnabled) {
            setTimeout(() => {
                if (this.scanRef) {
                    this.scanRef.readOnly = true;
                    this.scanRef.focus();
                    this.scanRef.readOnly = false;
                }
            }, time);
        } else if(this.scanRef) {
            this.scanRef.readOnly = true;
        }
    };

    render() {
        return (
            <Box className={"appScanner"}>
                <input id="app-scanner"
                       ref={(element) => {
                           this.scanRef = element;
                       }}
                       autoFocus={true}
                       autoComplete={"off"}
                       readOnly={"readOnly"}
                       type="text"
                       onKeyDown={this.keyDown}
                       onBlur={()=>this.requestFocus(20)}/>
            </Box>
        );
    }
}

AppScanner.propTypes = {
    scannerEnabled: PropTypes.bool.isRequired,
    onScan: PropTypes.func.isRequired
};

export default AppScanner;