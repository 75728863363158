import DataHandler from "../handler/DataHandler";
import loadExternal from "../utils/LoadExternal";
import Logger from "../utils/Logger";

const PUMA_LIB_URL = "https://d1x2hu8k357bsh.cloudfront.net/puma.js";

export default {
    moduleName : null,
    pumaInit(country, region, stage, appId, moduleName){
        this.moduleName = moduleName;
        loadExternal(PUMA_LIB_URL, (status) => {
            if (status) {
                window.PUMA.initialize({
                    appId: appId,
                    environment: {
                        country: country,
                        region: region,
                        stage: stage
                    },
                    // passing this flag to prevent PUMA from terminating on intent firing
                    disableTerminateOnUnload: true
                });
                Logger.log.info('PUMA initialized');
            } else Logger.log.error('PUMA not initialized');
        });
    },
    publishLatencyToDolphinCSM(startTime, metricName) {
        try {
            let metricValue = getTimeDiff(startTime);
            const latencyMetric = metricName + "-latency";

            // Publishing metrics with moduleName + stationCode
            const processNameWithStationCode = this.moduleName + DataHandler.getStationCode();
            const getPMETMetricWithStationCode = window.PUMA.createMethodContext(processNameWithStationCode);
            getPMETMetricWithStationCode.publishTimer(latencyMetric, metricValue);

            // Publishing metrics with moduleName
            const getPMETMetric = window.PUMA.createMethodContext(this.moduleName);
            getPMETMetric.publishTimer(latencyMetric, metricValue);
        } catch (e) {
            Logger.log.warn("Exception in PUMA Latency Publish");
        }
    },
    publishCountToDolphinCSM(metricName) {
        try {
            // Publishing metrics with moduleName + stationCode
            const processName = this.moduleName + DataHandler.getStationCode();
            const getPMETMetricWithStationCode = window.PUMA.createMethodContext(processName);
            getPMETMetricWithStationCode.publishCounter(metricName, 1);

            // Publishing metrics with moduleName
            const getPMETMetric = window.PUMA.createMethodContext(this.moduleName);
            getPMETMetric.publishCounter(metricName, 1);
        } catch (e) {
            Logger.log.warn("Exception in PUMA Count Publish");
        }
    }
}

function getTimeDiff(time) {
    return Date.now() - time ;
}