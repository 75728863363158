export default {
   Events : {
       OPERATION_API_CALL: "operation_api_call",
       USER_SCANNED_PACKAGE: "user_scanned_package",
       USER_LOADED_SCREEN: "user_loaded_screen"
   },
   Modules : {
       INDUCT_PACKAGE: "INDUCT_PACKAGE",
       INDUCT_INDUCT_LOCATION: "INDUCT_INDUCT_LOCATION",
       INDUCT_GET_SAL_FORMAT: "INDUCT_GET_SAL_FORMAT",
       DIRECTED_INDUCT: "Directed Induct",
       PWA_DIRECTED_INDUCT: "PWA Directed Induct",
       PWA_DIRECTED_INDUCT_PRINT: "PWA Directed Induct Print",
       PWA: "PWA",
       ACCESS_TOKEN_EXCHANGE: "PandaTokenExchange",
       FIXIT: "FixIt",
       GSF_FIXIT: "GSF_FIXIT",
       GET_SORT_INFO_FOR_PACKAGE: "GET_SORT_INFO_FOR_PACKAGE",
       PROCESS_STOW: "PROCESS_STOW",
       VALIDATE_LOCATION_BEFORE_STOW: "VALIDATE_LOCATION_BEFORE_STOW",
       VALIDATE_OPEN_BAG: "VALIDATE_OPEN_BAG",
       OPEN_BAG: "OPEN_BAG",
       VALIDATE_CONTAINER_BEFORE_ATTACH_OUTPUT: "VALIDATE_CONTAINER_BEFORE_ATTACH_OUTPUT",
       ATTACH_CONTAINER_TO_DESTINATION_OUTPUT: "ATTACH_CONTAINER_TO_DESTINATION_OUTPUT",
       GET_ROUTES_FOR_SORT_LOCATION: "GET_ROUTES_FOR_SORT_LOCATION",
       GET_ROUTES_STOW_PROGRESS_FOR_SORT_LOCATION: "GET_ROUTES_STOW_PROGRESS_FOR_SORT_LOCATION",
       STOW_GET_SAL_TEMPLATE_FOR_PRINTER: "STOW_GET_SAL_TEMPLATE_FOR_PRINTER",
       GET_SCOPE_FOR_NODE_OUTPUT: "GET_SCOPE_FOR_NODE_OUTPUT",
       SCRUB_CONTAINER: "SCRUB_CONTAINER",
       MOVE_TO_PROBLEM_SOLVE: "MOVE_TO_PROBLEM_SOLVE",
       GET_BAG_SORT_INFO_FOR_CLOSE: "GET_BAG_SORT_INFO_FOR_CLOSE",
       CLOSE_BAG: "CLOSE_BAG",
       CLOSE_CONTAINER_OUTPUT: "CLOSE_CONTAINER_OUTPUT",
       VALIDATE_CONTAINER_BEFORE_SCRUB: "VALIDATE_CONTAINER_BEFORE_SCRUB",
       SBL_SOURCE_SCAN: "SBL_SOURCE_SCAN",
       SBL_DESTINATION_SCAN: "SBL_DESTINATION_SCAN",
       BATCH_GET_ITEM_STAGE_INFO: "BATCH_GET_ITEM_STAGE_INFO",
       STOW: "STOW",
       PACKAGE_STOW: "PACKAGE_STOW"
   }
}