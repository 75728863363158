import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Alert from "@amzn/meridian/alert";
import PropTypes from 'prop-types';
import TopNavigationView from "../TopNavigationView";
import Column from "@amzn/meridian/column";

class WarningView extends Component {

    render() {
        const {warningMessage, title} = this.props;
        return (
            <Column>
                <TopNavigationView title={title} backgroundType={'warning'} onMenuButtonClicked={this.props.onBackClicked} optionsMenu={[]}/>
                <Alert type="warning" size="xlarge">
                    <FormattedMessage id={warningMessage}
                                      defaultMessage="Warning Message">
                    </FormattedMessage>
                </Alert>
            </Column>
        )
    };
}

WarningView.propTypes = {
    warningMessage: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    onBackClicked: PropTypes.func
};

export default WarningView;