import {dolphinAttributesBuilder} from "../model/DolphinAnalyticEvents";
import DeviceDetailsHelper from "./DeviceDetailsHelper";
import DataHandler from "../handler/DataHandler";

export function getDolphinAttributes(region) {
    const dolphinAttributes = new dolphinAttributesBuilder();

    dolphinAttributes.setCountry(DataHandler.getCountry())
        .setRegion(region)
        .setMarketplace(region)
        .setLocale(DataHandler.getUserLocale())
        .setTransporterId(DataHandler.getUserId())
        .setStationCode(DataHandler.getStationCode())
        .build();

    dolphinAttributes.setDeviceIMEI(DeviceDetailsHelper.getDeviceIMEI())
        .setDeviceModal(DeviceDetailsHelper.getDeviceModelName())
        .setDeviceManufacture(DeviceDetailsHelper.getDeviceManufacturer())
        .setDeviceOSVersion(DeviceDetailsHelper.getDeviceOSVersion())
        .setDeviceNetworkType(DeviceDetailsHelper.getDeviceNetworkType())
        .setBuildVersion(DeviceDetailsHelper.getBuildVersion())
        .build();

    return dolphinAttributes;
}