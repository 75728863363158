import React, {Component} from "react";
import PropTypes from "prop-types";
import SnapshotView from "../SnapshotView";
import Box from "@amzn/meridian/box";
import Text from "@amzn/meridian/text";
import Row from "@amzn/meridian/row";
import Column from "@amzn/meridian/column";

class DetailsCardView extends Component {

    createSnapshots = (snapshots, headerFontSize) => {
        return snapshots.map((currentSnapshot, index) =>
            <SnapshotView key={index} title={currentSnapshot.title} description={currentSnapshot.description}
                          headerFontSize={headerFontSize} padding={currentSnapshot.padding||"small"}/>
        )
    }

    setDisplay = (snapshots, display) => {
        switch(display) {
            case "Row" : return (<Row spacing="0">{snapshots}</Row>);
            case "Column": return (<Column spacing="0">{snapshots}</Column>);
            default: return snapshots;
        }
    }

    render() {
        const { backgroundColor, headerFontSize, snapshots, display, metadata } = this.props
        const dataSnapshots = this.createSnapshots(snapshots, headerFontSize)
        const displayData = this.setDisplay(dataSnapshots, display)
        return (
            <div>
                <Box type="outline">
                    <Box backgroundColor={backgroundColor} spacingInset="small">
                        {displayData}

                    </Box>
                    {metadata &&
                        <Box backgroundColor={metadata.backgroundColor} spacingInset="small" type="outline">
                            <Text color={metadata.fontColor} type="b200">{metadata.title}</Text>
                        </Box>
                    }
                </Box>
            </div>
        )
    }
}

DetailsCardView.propTypes = {
    backgroundColor: PropTypes.string,
    headerFontSize: PropTypes.string,
    display: PropTypes.string,
    snapshots: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        padding: PropTypes.string
    })).isRequired,
    metadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
        backgroundColor: PropTypes.string,
        fontColor: PropTypes.string,
    })
}


export default DetailsCardView;