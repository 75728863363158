import DataHandler from "./DataHandler";
import PWADomainHelper from "../helper/PWADomainHelper";

/**
 * Handles the invocation of a module and callback after completion
 * of operation by the invoked module with the required data
 */
export default {

    /**
     * Invoke another module with some data
     * @param fromModule    The module which started the invocation
     * @param toModule      The module which is to be invoked
     * @param toHost        The host name of the module to be invoked
     * @param toHash        The hash of the component in the module to be invoked
     * @param data          Data required to be sent
     * @param callbackHost  The host of the module which is to be opened after processing (Optional)
     * @param callbackHash  The hash of the module which is to be opened after processing (Optional)
     * @param toModulePathName  The pathname of module which is to be invoked
     * @param callbackPathName  The pathname of module which started the invocation
     */
    invokeModule(fromModule, toModule, toHost, toHash, data, callbackHost, callbackHash, toModulePathName, callbackPathName) {
        // Build redirect Data
        let redirectData = {
            redirectFromModule: fromModule,
            redirectToModule: toModule,
            data: data
        };

        if (callbackHost && callbackHash) {
            redirectData.callbackHost = callbackHost;
            redirectData.callbackHash = callbackHash;
            redirectData.callbackPathName = callbackPathName;
        }

        openURL(toHost, toHash, redirectData, toModulePathName);
    },

    /**
     * Clear the redirection data from URL
     */
    clearRedirectDataFromURL() {
        const url = new URL(window.location);
        if (url.searchParams.get(DataHandler.Params.REDIRECT_DATA)) {
            // Delete the redirect data search params
            url.searchParams.delete(DataHandler.Params.REDIRECT_DATA);
            // Replace the history entry
            window.history.replaceState({}, '', url.toString());
        }
    },

    /**
     * Redirect back to the module which invoked this module
     * @param data          Data after completion of operation
     */
    callback(data) {
        // Get the data that was send when this module was invoked
        let redirectData = DataHandler.getRedirectData();

        if (redirectData === null || redirectData.callbackHost === null) {
            throw "No callback data found";
        }

        this.invokeModule(redirectData.redirectToModule, redirectData.redirectFromModule,
            redirectData.callbackHost, redirectData.callbackHash, data, undefined,
            undefined, redirectData.callbackPathName);
    }
};

function openURL(toHost, hash, data, toPathName) {
    // Update URL
    let url = new URL(window.location.href);
    url.host = toHost;
    url.pathname = (toPathName)?toPathName:"";
    url.hash = hash;
    PWADomainHelper.getInitializationQueryParams().forEach((value, key) => {
        url.searchParams.set(key, value);
    });
    url.searchParams.set(DataHandler.Params.REDIRECT_DATA, JSON.stringify(data));

    // Open URL
    window.location.href = url.toString();
}