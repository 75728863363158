import './index.css';
import PropTypes from "prop-types";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";
import Text from "@amzn/meridian/text";
import Icon from "@amzn/meridian/icon";
import closeTokens from "@amzn/meridian-tokens/base/icon/close-large";
import Column from "@amzn/meridian/column";
import React from "react";

export default function BackPressedView(props) {

    return <Column className={"backdrop"} spacingInset={"small"}>
        <Row alignmentHorizontal="right" spacing={"none"}>
            <Button type="icon" onClick={props.onClose}>
                <Text color={"inverted"}><Icon tokens={closeTokens}/></Text>
            </Button>
        </Row>
        <Row spacingInset={"medium"}>
            <Text type="h400" color="inverted">{props.title}</Text>
        </Row>
        <Column spacingInset={"medium"}>
            <Text type="b200" color="inverted">{props.description}</Text>
        </Column>
        <Column className={"footer"} spacingInset={"medium"}>
            <Button onClick={props.primaryButtonOnClick}>{props.primaryButtonTitle}</Button>
            {props.secondaryButtonOnClick &&
            <Button type={"secondary"} onClick={props.secondaryButtonOnClick}>{props.secondaryButtonTitle}</Button>}
        </Column>
    </Column>;
}

BackPressedView.propType = {
    onClose: PropTypes.func,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    primaryButtonOnClick: PropTypes.func.isRequired,
    primaryButtonTitle: PropTypes.string.isRequired,
    secondaryButtonOnClick: PropTypes.func,
    secondaryButtonTitle: PropTypes.string
}