import React, {Component} from "react";
import PropTypes from "prop-types";
import Box from "@amzn/meridian/box";
import TextView from "../TextView";
import Divider from "@amzn/meridian/divider";

class HeaderView extends Component {
    render() {
        const {primaryTitle, secondaryTitle} = this.props
        return (
            <div align="center">
                <TextView  {...primaryTitle}/>
                {secondaryTitle &&
                    <div>
                        <Box spacingInset="small">
                            <Divider size="small"/>
                        </Box>
                        <TextView {...secondaryTitle} />
                    </div>}
            </div>
        )
    }
}

HeaderView.propTypes = {
    primaryTitle: PropTypes.shape({
        title: PropTypes.string.isRequired,
        size: PropTypes.string,
        type: PropTypes.string
    }).isRequired,
    secondaryTitle: PropTypes.shape({
        title: PropTypes.string.isRequired,
        size: PropTypes.string,
        type: PropTypes.string
    })
}

export default HeaderView;