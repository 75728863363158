import {DolphinAPIClientProvider} from "../index";
import {API_ROUTES, getUAASServiceBaseURL} from "../utils/BaseURLUtils";
import DataHandler from "../handler/DataHandler";


export default {
    async getModulesAndFeatures(stage, region) {
        const path = API_ROUTES.UAAS.GetModulesAndFeaturesForNodeRequest;
        const params = {
            params: {
                node: DataHandler.getStationCode()
            }
        };
        try {
            const UAASClient = getUAASClient(stage, region);
            const response = await UAASClient.get(path, params);
            return response.data;
        } catch (e) {
            return {features: [], modulesWithTrainingStatus: [], moduleVersionMap: {}};
            //TODO: Handle exception by showing a popup which forces user to retry this API
        }
    }
}

const getUAASClient = (stage, region) => {
    DolphinAPIClientProvider.generateDolphinAPIClient(getUAASServiceBaseURL(stage, region));
    return DolphinAPIClientProvider.dolphinAPIClient;
};