export default {
    Stage : {
        BETA: 'beta',
        GAMMA: 'gamma',
        PROD: 'prod',
        INTEG: 'integ',
        TEST: 'test',
        MASTER: 'master'
    },
    Region : {
        REGION_NA: "NA",
        REGION_EU: "EU",
        REGION_FE: "FE",
        REGION_IN: "IN",
        REGION_UK: "UK",
        REGION_JP: "JP",
        REGION_US: "US",
    },
    DolphinURL : {
        NA : {
            PROD: "https://dolphin.amazon.com",
            PREPROD: "https://dolphin-preprod.amazon.com"
        },
        EU : {
            PROD: "https://dolphin.amazon.eu",
            PREPROD: "https://dolphin-preprod.amazon.eu"
        },
        FE : {
            PROD: "https://dolphin-fe.amazon.com",
            PREPROD: "https://dolphin-fe-preprod.amazon.com"
        },
        IN : {
            PROD: "https://dolphin.amazon.eu/in",
            PREPROD: "https://dolphin-preprod.amazon.eu/in"
        }
    },
    RegionLowerCase: {
        REGION_NA: "na",
        REGION_EU: "eu",
        REGION_FE: "fe",
        REGION_IN: "in",
        REGION_JP: "jp"
    },
    ErrorCode: {
        DEVICE_OFFLINE : "DEVICE_OFFLINE"
    }
}