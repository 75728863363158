import axios from "axios";
import {authInterceptorBuilder} from "./AuthInterceptor";

export default {

    poll: false,
    dolphinAPIClient: null,

    /**
     * Open Home activity and close this TWA
     * @returns {Promise<*>}
     */
    async generateDolphinAPIClient(baseUrl) {
        const dolphinAPIClient = axios.create({
            baseURL: baseUrl
        });

        const authInterceptor = authInterceptorBuilder(dolphinAPIClient);

        dolphinAPIClient.interceptors.request.use(authInterceptor.onBeforeRequest, authInterceptor.onRequestError);
        dolphinAPIClient.interceptors.response.use(authInterceptor.onResponseSuccess, authInterceptor.onResponseError);

        this.dolphinAPIClient = dolphinAPIClient;
    }
}