import React, {Component} from "react";
import PropTypes from "prop-types";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";

class SnapshotView extends Component {

    getHeaderFontSize = (fontSize) => {
        switch (fontSize) {
            case "Small" : return "h200";
            case "Medium" : return "h300";
            case "Large" : return "h500";
            default : return "h200";
        }
    }


    render() {
        const {title, description, headerFontSize, padding} = this.props
        const fontSize = this.getHeaderFontSize(headerFontSize)
        return (
            <Row alignmentHorizontal="left" spacingInset={padding||"small"}>
                <div align="left">
                    <Text type={fontSize}>{title}</Text>
                    <Text type="b200">{description}</Text>
                </div>
            </Row>
        )
    }
}

SnapshotView.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    headerFontSize: PropTypes.string
}

export default SnapshotView;